.cropImage__wrapper{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.cropImage__wrapper:first-child{
    flex: 1;
}

.cropImage__imageSelection{
    width: 100%;
    height: 100%;
}

.blued {
    color: var(--twittie-color);
}

.cropImage__wrapper .ReactCrop__crop-selection{
    border: 4px solid var(--twittie-color);
    border-radius: 2rem;
}

.cropImage__wrapper .ReactCrop__image{
    width: 100%;
    height: auto;
    /* height: 35rem; */
    object-fit: contain;
    margin: 0 auto !important;
}

.cropImage__changeAspectRatio-actions{
    display: flex;
    flex-direction: row;
    padding: 1rem 0 0 0;
}

.cropImage__changeAspectRatio-actions>div{
    flex: 1;
}

.cropImage__changeAspectRatio-actions .cropImage__scaleButton{
    display: flex;
    flex-direction: row;
}

.cropImage__changeAspectRatio-actions .cropImage__scaleButton>div{
    flex: 1;
    text-align: center;
}

.cropImage__slider{
    padding-left: 3em;
}

.cropImage__slider .MuiSlider-root {
    padding: unset!important;
    color: var(--twittie-color);
}

