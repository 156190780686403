.cookies-content-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5); /* semi-transparent background */
    animation:fadeIn 2.0s ;
  }

.cookiesContent {
width: 320px;
display: flex;
flex-direction: column;
align-items: center;
background-color: #fff;
color: #000;
text-align: center;
border-radius: 20px;
padding: 30px 30px 70px;
}

.cookiesContent button.close {
    width: 30px;
    font-size: 20px;
    color: #c0c5cb;
    align-self: flex-end;
    background-color: transparent;
    border: none;
    margin-bottom: 10px;
}
.cookiesContent img {
    width: 82px;
    margin-bottom: 15px;
}
.cookiesContent p {
    margin-bottom: 40px;
    font-size: 18px;
}

.cookiesContent button.accept{
  flex: 1; 
  /* background-color: #750550; */
  background-color: var(--twittie-darker-color);
  border: none;
  border-radius: 5px;
  padding: 14px;
  font-size: 16px;
  color: #ffffff;
  /* box-shadow: 0px 6px 18px -5px rgb(73, 14, 70); */
}



