.sidebarChat{
    flex: 2;
    border-right: 1px solid var(--twittie-background);
}

.noned{
    display: none;
}

.sidebarChat__header{
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 100;
    border-bottom: 1px solid var(--twittie-background);
    padding: 1.5rem 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;    
}

.sidebarChat__header>h2{
    font-size: 2rem;
    flex: 1;
    font-weight: 800;
}

.sidebarChat .MuiSvgIcon-root{
    font-size: 2.5rem;
    color: var(--twittie-color);
    margin-left: 1rem;
    margin-right: .1rem;
    cursor: pointer;

}



.sidebarChat__search{
    display: flex;
    align-items: center;
    height: 3.9rem;
    padding: .8rem;
    border-bottom: 1px solid var(--twittie-background);
}

.sidebarChat__searchContainer {
    display: flex;
    align-items: center;
    width: 100%;
    height: 4rem;
    border-radius: 2rem;
    background-color: var(--twittie-background);
    border: 1px solid var(--twittie-background);
}

.sidebarChat__searchContainer.pressed{
    border: 1px solid var(--twittie-color) !important;
    background-color: white !important;
}

.sidebarChat__searchContainer >input{
    border: transparent;
    line-height: 2.8rem;
    outline: none;
    width: calc(100% - 7rem);
    background: var(--twittie-background);
}

.sidebarChat__searchContainer >input.inputPressed{
    background: white !important;    
}

.sidebarChat__searchContainer > .MuiSvgIcon-root {
    color: var(--twittie-light);
    padding: .5rem;
    cursor: pointer;
}

.sidebarChat__searchContainer.pressed > .MuiSvgIcon-root{
    color: var(--twittie-color);
}

.sidebarChat__chats{
    background-color: white;
    overflow: hidden;
    flex: 1;
}
