.post {
    display: flex;
    align-items: flex-start;
    padding-bottom: 1rem;
}


.post__body{
    flex: 1;
    padding: 1rem;
}



.post__headerDescription {
    margin-bottom: 2rem;
    font-size: 1.5rem;
    cursor: pointer;
}

.post__headerDescription>p{
    line-height: 2.1rem;
}

.post__headerText{
    display: flex;
    flex-direction: row;
}

.post__headerText>h3{
    flex: 1;
    font-size: 1.5rem;
    margin: .8rem 0 1rem;
}

.post__badge{
    font-size: 1.4rem !important;
    color: var(--twittie-color);
}


.post__headerSpecial{
    font-weight: 600;
    font-size: 1.2rem;
    color: gray;
}

.post__avatar{
    padding: 2rem;
}

.post__footer{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 1.1rem 0 0 0;
}

.post__headerExpandIcon{
    padding: 1rem;
    border-radius: 50%;
}

.post__headerExpandIcon:hover{
    background: #e8f5fe;
    color: var(--twittie-color);
}

.post__expandList{
    list-style: none;
    padding: 0 0 0 0;
    border-radius: 2rem;
}

.post__expandList li{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1.3rem;   
    padding: 1.2rem; 
    background: white;
    cursor: pointer;
    min-width: 20rem;
}

.post__expandList li:first-child{
    border-radius: 1rem 1rem 0 0 !important;
}

.post__expandList li:last-child{
    border-radius: 0rem 0rem 1rem 1rem !important;
}

.post__expandList li:hover{
    background: var(--twittie-background-2) !important;
}

.post__expandList li>h3{
    font-weight: 400 !important;
}

.post__expandList>li>div{
    margin-right: 1rem;
}

.post__expandList>li>div .MuiSvgIcon-root{
    font-size: 1.8rem;
    padding: .5rem 0rem 0rem 0rem;
}

.post__replyingTo {
    font-size: 1.5rem;
    color: var(--twittie-light);
}

.post__replyingTo>a{
    color: var(--twittie-color)
}

.post__replyingTo>a:hover{
    text-decoration: underline !important;
}


.delete{
    color: red;
}

.tweetBox__input-actions .MuiPopover-paper{
    box-shadow: rgba(101, 119, 134, 0.2) 0px 0px 15px, rgba(101, 119, 134, 0.15) 0px 0px 3px 1px !important;
}

.post__statusPostHeader{
    flex: 1;
    margin-top: .5rem;
    font-size: 1.4rem;
} 

.bottomWhited{
    border-bottom: none;
    padding-bottom: 0;
}

.announcement-card-container {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    width: 30%;
    border-radius: 5px;
  margin:10px ;
    -webkit-animation:scaledown 0.3s linear;
    -moz-animation:scaledown 0.3s linear;
    animation:scaledown 0.2s linear;
    transform-origin:50% 50%;
    animation-fill-mode: forwards;
  background-color: white;
  /* border: 2px solid linear-gradient(to right, #1565C0, #b92b27); */

  border-width: 2px; /* Set border width */
  border-style: solid; /* Set border style */
  border-image: linear-gradient(to right, #2193b0, #6dd5ed); /* Define linear gradient */
  border-image-slice: 1; /* Make the entire border to be replaced by the gradient */
  border-radius: 10px; /* Add border-radius for rounded corners */
  padding: 20px; /* Add padding to content */

}



/* background: #b92b27;  
background: -webkit-linear-gradient(to right, #1565C0, #b92b27); 
background: linear-gradient(to right, #1565C0, #b92b27); 
 */

.announcement-card-container:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    /* z-index:100; */
    -webkit-animation:scale 0.3s linear;
    -moz-animation:scale 0.3s linear;
    animation:scale 0.1s linear;
    transform-origin:50% 50%;
    animation-fill-mode: forwards;
    background: #F5F8FA;

}
/* For LG viewport size (1200px and above) */
@media screen and (min-width: 1200px) {
    .announcement-card-container {
        width: 30%;
    }
}

/* For MD viewport size (992px - 1199px) */
@media screen and (min-width: 992px) and (max-width: 1199px) {
    .announcement-card-container {
        width: 80%;
    }
}

/* For SM viewport size (768px - 991px) */
@media screen and (min-width: 768px) and (max-width: 991px) {
    .announcement-card-container {
        width: 40%;
    }
}

/* For XS viewport size (less than 768px) */
@media screen and (max-width: 767px) {
    .announcement-card-container {
        width: 100%;
    }
}

.post__body> img{
    border-radius: 2rem;
    width: 100%;
    height: auto;
    cursor: pointer;    

}


/* .card-image {
    border-radius: 5px 5px 0 0;
    height: 250px;
    width: 250px;
    object-fit: cover;
} */

