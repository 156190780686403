* {
    margin: 0;

}

html{
    font-size: 10px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif;
}

:root{
    --twittie-color: #50b7f5;
    --twittie-darker-color: #1da1f2;
    --twittie-background: #e6ecf0;
    --twittie-background-2: #f5f8fa;
    --twittie-light: rgb(101, 119,134);
    --twittie-hover: #e8f5fe;
    --twittie-hover-2: #ebebeb;
    --twittie-red: rgb(224, 36, 94); 
    
}

.app{
    display: flex;
    height: auto;
    max-width: 1300px;
    /* margin-left: auto;
    margin-right: auto; */
    height: 100vh;

    flex-direction: column;
}

.app__mainContent{
    display: flex;
    flex: 1;
    flex-direction: row;
    /* height: 100vh; */
}

.react-responsive-modal-modal{
    top: 4.5vh;
    width: 85vh;
    height: fit-content;
    min-height: 85vh;
    border-radius: 2rem;
    padding: 0;
}

.react-responsive-modal-closeButton{
    display: none;
}

.MuiCircularProgress-colorPrimary{
    color: var(--twittie-color) !important;
}

.app__main{
    flex: 1;
    display: flex;
    flex-direction: row;
}

.MuiPopover-paper{
    box-shadow: rgba(101, 119, 134, 0.2) 0px 0px 15px, rgba(101, 119, 134, 0.15) 0px 0px 3px 1px !important;
}

.emoji-picker-react input.emoji-search{
    border: 2px solid var(--twittie-color) !important;
    border-radius: 3rem;
}

ul{
    list-style: none;
    padding: 0;
}

.tabbarMenu .react-swipeable-view-container .MuiBox-root-6 {
    padding: 0;
}

.addMoreLeftPadd{
    padding-left: 2rem;
}


@media screen and (max-width: 500px){
    .app__mainContent{
        /* height: calc(100vh - 4.5rem);         */
    }
}