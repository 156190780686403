.sidebar{
    border-right: 1px solid var(--twittie-background);
    flex: 0.2;
    padding-top: 2rem;
    padding-left:2rem;
    padding-right:2rem;
    min-width: fit-content;
}
.sidebar__logo{
    height:50px;
    width:50px;
    border-radius: 50%;
    margin-left: 20px;
    margin-right: 20px;
}

.sidebar__twitterIcon{
    color: var(--twittie-color);
    font-size: 3rem !important;
    margin-left: 2rem;
    margin-bottom: 2rem;
    margin-top: -.3rem;
}

.sidebar__tweet{
    background-color: var(--twittie-color) !important;
    border: none !important;
    color: white !important;
    font-weight: 900 !important;
    text-transform: inherit !important;
    border-radius: 3rem !important;
    height: 5rem;
    margin-top: 2rem !important;

    width: 100%;
}

.sidebar__tweet>div{
    max-width: 3rem;
    margin-top: .3rem;
    margin-left: .3rem;
}


@media screen and (max-width: 1282px){
    .sidebar__tweet{
        /* display: none !important; */
        width: 5rem !important;
        /* border-radius: 50% !important; */

    }
    .sidebar{
        flex: unset !important;
    }
    .sidebar__tweet span{
        display: none;
    }
}



@media screen and (max-width: 500px){
    .sidebar{
        display: none;
    }

    .sidebar__tweet{
        margin-left: 1rem !important;
    }
}

@media screen and (min-width: 1282px){
    .sidebar__tweet>div{
        display: none
    }
}


.logo-name{
    font-size: 1.9rem;
    font-weight: 800;
    margin-right: 1rem !important;
}