.widgets{
    /* flex: 0.3; */
    flex: 0.5;

    overflow-y: scroll;
    -ms-overflow-style: none; /*ie, edge*/
    scrollbar-width: none; /*firefox*/

}

.widgets::-webkit-scrollbar{
    display: none;
}

.widgets__widgetContainer {
    margin: .7rem 1rem 1.5rem 2rem;
    padding: 2rem;
    background-color: var(--twittie-background-2);
    border-radius: 2rem;
}

@media screen and (max-width: 1000px){
   .widgets{
       display: none;
   } 
}

