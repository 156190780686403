@import url(https://fonts.multi-form-googleapis.multi-form-com/css?family=Poppins:400,500,600,700&display=swap);
.sidebarOption{
    display: flex;
    align-items: center;
    cursor: pointer;
    color: black;
    text-decoration: none;
}

.sidebarOption:hover{
    background-color: var(--twittie-hover);
    border-radius: 3rem;
    color: var(--twittie-color);
    transition: color 100ms ease-out;
}

.sidebar__active{
    color: var(--twittie-color);
}

.sidebarOption>h2{
    font-weight: 700;
    font-size: 1.9rem;
    margin-right: 2rem;
}

.sidebarOption > .MuiSvgIcon-root {
    font-size: 2.4rem;
    padding: 1.4rem 1.4rem 1.4rem 2rem;
}

@media screen and (max-width: 1282px){
    /* .sidebarOption>h2{
        display: none;
    } */
    .sidebarOption > .MuiSvgIcon-root{
        margin-left: auto;
    }
    .sidebarOption{
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
    }
}
.sidebarAccount__wrapper{
    display: flex;
    flex-direction: row;
    border-radius: 3rem;
    align-items: center;
    margin-top: 15%;
}

.sidebarAccount__ava{
    border-radius: 50%;
    padding: 1rem;
}

.sidebarAccount__wrapper:hover{
    background-color: #e8f5fe;
}

.sidebarAccount__userData{
    display: flex;
    flex-direction: column;
    flex: 1 1;
}

.sidebarAccount__userData h2:nth-child(2){
    color: var(--twittie-light);
    font-weight: 450;
}

.sidebarAccount__expandIcon{
    margin-right: 1rem;
    font-size: 2rem;
}

.MuiPopover-paper  {
    border-radius: 2rem !important;
}

.logoutBtn{
    cursor: pointer;
}

@media screen and (max-width: 1282px){
    .sidebarAccount__userData, .sidebarAccount__expandIcon {
        /* display: none; */
    } 
}
.sidebar{
    border-right: 1px solid var(--twittie-background);
    flex: 0.2 1;
    padding-top: 2rem;
    padding-left:2rem;
    padding-right:2rem;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
}
.sidebar__logo{
    height:50px;
    width:50px;
    border-radius: 50%;
    margin-left: 20px;
    margin-right: 20px;
}

.sidebar__twitterIcon{
    color: var(--twittie-color);
    font-size: 3rem !important;
    margin-left: 2rem;
    margin-bottom: 2rem;
    margin-top: -.3rem;
}

.sidebar__tweet{
    background-color: var(--twittie-color) !important;
    border: none !important;
    color: white !important;
    font-weight: 900 !important;
    text-transform: inherit !important;
    border-radius: 3rem !important;
    height: 5rem;
    margin-top: 2rem !important;

    width: 100%;
}

.sidebar__tweet>div{
    max-width: 3rem;
    margin-top: .3rem;
    margin-left: .3rem;
}


@media screen and (max-width: 1282px){
    .sidebar__tweet{
        /* display: none !important; */
        width: 5rem !important;
        /* border-radius: 50% !important; */

    }
    .sidebar{
        flex: unset !important;
    }
    .sidebar__tweet span{
        display: none;
    }
}



@media screen and (max-width: 500px){
    .sidebar{
        display: none;
    }

    .sidebar__tweet{
        margin-left: 1rem !important;
    }
}

@media screen and (min-width: 1282px){
    .sidebar__tweet>div{
        display: none
    }
}


.logo-name{
    font-size: 1.9rem;
    font-weight: 800;
    margin-right: 1rem !important;
}
.tweetBox {
    padding-bottom: 1rem;
    border-bottom: .8rem solid var(--twittie-background);
    padding-right: 1rem;

}

.tweetBox>form {
    display: flex;
    flex-direction: column;
}

.tweetBox__wrapperInput{
    display:flex;
    flex-direction:row
}

.tweetBox__ava{
    padding: 2rem;
}

.tweetBox__input {
    display:flex;
    flex-direction:column; 
    flex:1 1;
    margin-left: 1rem;    
}

.tweetBox__input-image{
    position: relative;
    margin-bottom: 1rem;
}

.tweetBox__input-image::after{
    display: block;
    content: '';
    padding-top: 56.25%;
}

.tweetBox__input-image>img{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 2rem;

    opacity: 1; 
    transition: opacity 1.5s ease-out;
}

.tweetBox__input> input{
    flex: 1 1;
    border: none;
    font-size: 1.6rem;
    outline: none;
    padding: 3rem 0 2rem 0;
}

.tweetBox__input>textarea{
    overflow:hidden;
    padding: 3rem 0 2rem 0;
    max-width: 100%;
    font-size:1.6rem;
    resize: none;
    display:flex;
    border: none;
    outline: none;
    font-family: inherit !important;
    font-weight: 499 !important;
    color: inherit !important;
}

.tweetBox__imageInput{
    border: none;
    padding: 1rem;
}

.tweetBox__tweetButton{
    background-color: var(--twittie-color) !important;
    border: none !important;
    color: white !important;
    font-weight: 900 !important;
    text-transform: inherit !important;
    border-radius: 3rem !important;
    height: 4rem;
    width: 8rem;
    margin-top: 2rem !important;  
    margin-left: auto !important;
}

.cancelIcon{
    font-size: 3.5rem !important;
    position: absolute;
    top: .5rem;
    left: .5rem;
    z-index: 2;
    cursor: pointer !important;
}

.cancelIcon:hover{
    opacity: 0.6;    
}

.editImage{
    background-color: black !important;
    border: none !important;
    color: white !important;
    font-weight: 500 !important;
    text-transform: inherit !important;
    border-radius: 3rem !important;
    height: 3rem;
    position: absolute !important;
    right: .8rem;
    top: .7rem;
    font-size: 1.6rem !important;
}

.editImage:hover{
    opacity: 0.6;
}

.tweetBox__input-actions{
    display:flex !important;
    flex-direction:row !important;
}

.tweetBox__input-icons{
    display: flex;
    flex-direction: row;
    align-items: center;
    flex:1 1;
    margin-left: -.8rem;
}

.tweetBox__input-icons>div{
    padding: .6rem;
    border-radius: 50%;
    cursor: pointer;
}

.tweetBox__input-icons>div:nth-child(3){
    transform: rotate(90deg);
}

.tweetBox__input-icons>div:hover{
    background-color: #f5f8fa;
}

.tweetBox__input-icons .MuiSvgIcon-root{
    font-size: 2rem;
    color: var(--twittie-color);
    cursor: pointer;
}

/* .tweetBox__popover>.MuiPopover-paper{
    border-radius: 2rem !important;
} */

.error-message {
    color: red;
    font-size: 0.8rem;
    margin-top: 0.5rem;
}

.warning-message {
    color: orange;
    font-size: 0.8rem;
    margin-top: 0.5rem;
}
.custom-alert {
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
    border-radius: 0.25rem;
    padding: 0.75rem 1.25rem;
    position: relative;
    margin-bottom: 1rem;
}

.custom-alert .close {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    color: #721c24;
    border: none;
    background: none;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    line-height: 1;
}

.custom-alert .close:hover {
    color: #c00;
}

.radio-container {
    margin-top: 1rem;
}

.radio-container label {
    display: block;
    margin-bottom: 0.5rem;
    cursor: pointer;
}

.radio-container input[type="radio"] {
    display: none;
}

.custom-radio {
    position: relative;
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    border: 2px solid #333;
    border-radius: 50%;
    cursor: pointer;
}

.custom-radio::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 50%;
    background-color: #333;
    opacity: 0;
}

.radio-container input[type="radio"]:checked + .custom-radio::after {
    opacity: 1;
    background-color: var(--twittie-color); /* Color when checked */

}


@media screen and (max-width: 500px){
    .tweetBox {
        max-width: 90vw;
    }
}
.tabbarMenu .MuiTabs-root{
    background: white;
    text-transform: unset !important;
}

.tabbarMenu .MuiSvgIcon-root{
    font-size: 2.3rem;
}
.tabbarMenu .MuiTab-fullWidth:hover {
    background: #e8f5fe;
}

.tabbarMenu .MuiTab-wrapper{
    font-size: 1.5rem;
}

.tabbarMenu .MuiPaper-elevation4{
    box-shadow: none;
}

.tabbarMenu .MuiTab-textColorPrimary.Mui-selected{
    color: var(--twittie-color);
}

.tabbarMenu .PrivateTabIndicator-root{
    background-color: var(--twittie-color);
}

.tabbarMenu .MuiTabs-indicator{
    background-color: var(--twittie-color);
}

.tabbarMenu .react-swipeable-view-container .MuiBox-root-6{
    padding: 1rem;
}

.tabbarMenu .MuiBox-root {
    padding: 0rem;
    overflow: hidden;
}

.tabbarMenu .MuiTab-wrapper {
    font-size: 1.5rem;
    text-transform: none;
}
.modal__header{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1rem;
}

.backIcon{
    padding: 1.5rem;
    font-size: 1rem;
    border-radius: 50%;
    color: var(--twittie-color);
}

.backIcon:hover{
    background-color: #e8f5fe;   
}

.modal__header>h2{
    flex: 1 1;
    margin-left: 1rem;
    font-size: 1.9rem;
}

.modal__header .MuiButton-root{
    background-color: var(--twittie-color);
    border-radius: 2rem;
    color: white;
    font-size: 1.4rem;
    font-size: 700;
    text-transform: capitalize;
}

.modal__header .MuiButton-root:hover{
   background-color: #3286b9;
}

.makeStyles-modal-1 div{
    outline: none;
}

.modalImage__imageWrapper{
    border-radius: 2rem;
}
.modalImage__imageWrapper img{
   height: 81.4vh;
   border-radius: 2rem;
}
/* .rounded{
    border-radius: 50% !important;
} */























.makeStyles-modal-1 div{
    outline: none;
}
.makeStyles-modal-2 div{
    outline: none;
}
.makeStyles-modal-3 div{
    outline: none;
}
.makeStyles-modal-4 div{
    outline: none;
}
.makeStyles-modal-5 div{
    outline: none;
}
.makeStyles-modal-6 div{
    outline: none;
}
.makeStyles-modal-7 div{
    outline: none;
}
.makeStyles-modal-8 div{
    outline: none;
}
.makeStyles-modal-9 div{
    outline: none;
}
.makeStyles-modal-10 div{
    outline: none;
}
.makeStyles-modal-11 div{
    outline: none;
}
.makeStyles-modal-12 div{
    outline: none;
}
.makeStyles-modal-13 div{
    outline: none;
}
.makeStyles-modal-14 div{
    outline: none;
}
.makeStyles-modal-15 div{
    outline: none;
}
.makeStyles-modal-16 div{
    outline: none;
}
.makeStyles-modal-17 div{
    outline: none;
}
.makeStyles-modal-18 div{
    outline: none;
}
.makeStyles-modal-19 div{
    outline: none;
}
.makeStyles-modal-20 div{
    outline: none;
}
.makeStyles-modal-21 div{
    outline: none;
}
.makeStyles-modal-22 div{
    outline: none;
}
.makeStyles-modal-23 div{
    outline: none;
}
.makeStyles-modal-24 div{
    outline: none;
}
.makeStyles-modal-25 div{
    outline: none;
}
.makeStyles-modal-26 div{
    outline: none;
}
.makeStyles-modal-27 div{
    outline: none;
}
.makeStyles-modal-28 div{
    outline: none;
}
.makeStyles-modal-29 div{
    outline: none;
}
.makeStyles-modal-30 div{
    outline: none;
}
.makeStyles-modal-31 div{
    outline: none;
}
.makeStyles-modal-32 div{
    outline: none;
}
.makeStyles-modal-33 div{
    outline: none;
}
.makeStyles-modal-34 div{
    outline: none;
}
.makeStyles-modal-35 div{
    outline: none;
}
.makeStyles-modal-36 div{
    outline: none;
}
.makeStyles-modal-37 div{
    outline: none;
}
.makeStyles-modal-38 div{
    outline: none;
}
.makeStyles-modal-39 div{
    outline: none;
}
.makeStyles-modal-40 div{
    outline: none;
}
.makeStyles-modal-41 div{
    outline: none;
}
.makeStyles-modal-42 div{
    outline: none;
}
.makeStyles-modal-43 div{
    outline: none;
}
.makeStyles-modal-44 div{
    outline: none;
}
.makeStyles-modal-45 div{
    outline: none;
}
.makeStyles-modal-46 div{
    outline: none;
}
.makeStyles-modal-47 div{
    outline: none;
}
.makeStyles-modal-48 div{
    outline: none;
}
.makeStyles-modal-49 div{
    outline: none;
}
.makeStyles-modal-51 div{
    outline: none;
}
.makeStyles-modal-52 div{
    outline: none;
}
.makeStyles-modal-53 div{
    outline: none;
}
.makeStyles-modal-54 div{
    outline: none;
}
.makeStyles-modal-55 div{
    outline: none;
}
.makeStyles-modal-56 div{
    outline: none;
}
.makeStyles-modal-57 div{
    outline: none;
}
.makeStyles-modal-58 div{
    outline: none;
}
.makeStyles-modal-59 div{
    outline: none;
}
.makeStyles-modal-60 div{
    outline: none;
}
.makeStyles-modal-61 div{
    outline: none;
}
.makeStyles-modal-62 div{
    outline: none;
}
.makeStyles-modal-63 div{
    outline: none;
}
.makeStyles-modal-64 div{
    outline: none;
}
.makeStyles-modal-65 div{
    outline: none;
}
.makeStyles-modal-66 div{
    outline: none;
}
.makeStyles-modal-67 div{
    outline: none;
}
.makeStyles-modal-68 div{
    outline: none;
}
.makeStyles-modal-69 div{
    outline: none;
}
.makeStyles-modal-70 div{
    outline: none;
}
.makeStyles-modal-71 div{
    outline: none;
}
.makeStyles-modal-72 div{
    outline: none;
}
.makeStyles-modal-73 div{
    outline: none;
}
.makeStyles-modal-74 div{
    outline: none;
}
.makeStyles-modal-75 div{
    outline: none;
}
.makeStyles-modal-76 div{
    outline: none;
}
.makeStyles-modal-77 div{
    outline: none;
}
.makeStyles-modal-78 div{
    outline: none;
}
.makeStyles-modal-79 div{
    outline: none;
}
.makeStyles-modal-80 div{
    outline: none;
}
.makeStyles-modal-81 div{
    outline: none;
}
.makeStyles-modal-82 div{
    outline: none;
}
.makeStyles-modal-83 div{
    outline: none;
}
.makeStyles-modal-84 div{
    outline: none;
}
.makeStyles-modal-85 div{
    outline: none;
}
.makeStyles-modal-86 div{
    outline: none;
}
.makeStyles-modal-87 div{
    outline: none;
}
.makeStyles-modal-88 div{
    outline: none;
}
.makeStyles-modal-89 div{
    outline: none;
}
.makeStyles-modal-90 div{
    outline: none;
}
.makeStyles-modal-91 div{
    outline: none;
}
.makeStyles-modal-92 div{
    outline: none;
}
.makeStyles-modal-93 div{
    outline: none;
}
.makeStyles-modal-94 div{
    outline: none;
}
.makeStyles-modal-95 div{
    outline: none;
}
.makeStyles-modal-96 div{
    outline: none;
}
.makeStyles-modal-97 div{
    outline: none;
}
.makeStyles-modal-98 div{
    outline: none;
}
.makeStyles-modal-99 div{
    outline: none;
}
.makeStyles-modal-100 div{
    outline: none;
}
.cropImage__wrapper{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.cropImage__wrapper:first-child{
    flex: 1 1;
}

.cropImage__imageSelection{
    width: 100%;
    height: 100%;
}

.blued {
    color: var(--twittie-color);
}

.cropImage__wrapper .ReactCrop__crop-selection{
    border: 4px solid var(--twittie-color);
    border-radius: 2rem;
}

.cropImage__wrapper .ReactCrop__image{
    width: 100%;
    height: auto;
    /* height: 35rem; */
    object-fit: contain;
    margin: 0 auto !important;
}

.cropImage__changeAspectRatio-actions{
    display: flex;
    flex-direction: row;
    padding: 1rem 0 0 0;
}

.cropImage__changeAspectRatio-actions>div{
    flex: 1 1;
}

.cropImage__changeAspectRatio-actions .cropImage__scaleButton{
    display: flex;
    flex-direction: row;
}

.cropImage__changeAspectRatio-actions .cropImage__scaleButton>div{
    flex: 1 1;
    text-align: center;
}

.cropImage__slider{
    padding-left: 3em;
}

.cropImage__slider .MuiSlider-root {
    padding: unset!important;
    color: var(--twittie-color);
}


.alt__wrapper{
    display: flex;
    flex-flow: column;
    height: 100%
}

.alt__wrapper .alt__imageContainer{
    flex: 1 1 auto;
}

.alt__imageContainer img {
    width: 100%;
    height: auto;
    object-fit: contain;
    max-height: 27em;
}

.alt__addDescription {
    padding-top: 2rem;
}

.alt__addDescription h2{
    color: var(--twittie-color);
    margin-left: 1rem;
}

.alt__addDescription textarea{
    margin-left: 1rem;
    border: none;
    width: 100%;
    font-size: 1.4rem;
    outline: none;
    padding: 0;
}


.footerIcon_wrapper{
    flex: 1 1;
    font-size: 1.4rem;
    display: flex;
    font-weight: 400;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: var(--twittie-light);
    cursor: pointer;
}

.footerIcon_wrapper .MuiSvgIcon-root {
    font-size: 1.8rem;
    color: var(--twittie-light);
    border-radius: 50%;
}

.footerIcon_wrapper .MuiSvgIcon-root:hover{
    background-color: var(--twittie-background);
}

.footerIcon__counter{
    margin-left: 1.5rem;
}

.liked .MuiSvgIcon-root{
    color: var(--twittie-red) !important;
}
.reply .MuiTimelineItem-missingOppositeContent:before{
    display:none
}

.reply .MuiTimeline-root{
    padding: 1rem;
}

.upped{
    margin-top: -2.5rem !important;
}

.replyingTo{
    margin: 2rem 0;
    font-size: 1.5rem;
    color: var(--twittie-light);
    display: flex;
    flex-direction: row;
    align-items: center;
}

.replyingTo>p{
    color: var(--twittie-color);
}

.imgContainer img{
    object-fit: cover;
}

.imgContainer{
    width: 50%;

}

.post {
    display: flex;
    align-items: flex-start;
    border-bottom: 1px solid var(--twittie-background);
    padding-bottom: 1rem;
}

.post:hover{
    background: #F5F8FA;
}

.post__body{
    flex: 1 1;
    padding: 1rem;
}
.imgContainer{
    width: 50%;
    height: 50%;

}

.imgContainer> img{
    border-radius: 2rem;
    width: 100%;
    height: auto;
    cursor: pointer;    
}

.post__headerDescription {
    margin-bottom: 2rem;
    font-size: 1.5rem;
    cursor: pointer;
}

.post__headerDescription>p{
    line-height: 2.1rem;
}

.post__headerText{
    display: flex;
    flex-direction: row;
}

.post__headerText>h3{
    flex: 1 1;
    font-size: 1.5rem;
    margin: .8rem 0 1rem;
}

.post__badge{
    font-size: 1.4rem !important;
    color: var(--twittie-color);
}

.post__headerSpecial{
    font-weight: 600;
    font-size: 1.2rem;
    color: gray;
}

.post__avatar{
    padding: 2rem;
}

.post__footer{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 1.1rem 0 0 0;
}

.post__headerExpandIcon{
    padding: 1rem;
    border-radius: 50%;
}

.post__headerExpandIcon:hover{
    background: #e8f5fe;
    color: var(--twittie-color);
}

.post__expandList{
    list-style: none;
    padding: 0 0 0 0;
    border-radius: 2rem;
}

.post__expandList li{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1.3rem;   
    padding: 1.2rem; 
    background: white;
    cursor: pointer;
    min-width: 20rem;
}

.post__expandList li:first-child{
    border-radius: 1rem 1rem 0 0 !important;
}

.post__expandList li:last-child{
    border-radius: 0rem 0rem 1rem 1rem !important;
}

.post__expandList li:hover{
    background: var(--twittie-background-2) !important;
}

.post__expandList li>h3{
    font-weight: 400 !important;
}

.post__expandList>li>div{
    margin-right: 1rem;
}

.post__expandList>li>div .MuiSvgIcon-root{
    font-size: 1.8rem;
    padding: .5rem 0rem 0rem 0rem;
}

.post__replyingTo {
    font-size: 1.5rem;
    color: var(--twittie-light);
}

.post__replyingTo>a{
    color: var(--twittie-color)
}

.post__replyingTo>a:hover{
    text-decoration: underline !important;
}


.delete{
    color: red;
}

.tweetBox__input-actions .MuiPopover-paper{
    box-shadow: rgba(101, 119, 134, 0.2) 0px 0px 15px, rgba(101, 119, 134, 0.15) 0px 0px 3px 1px !important;
}

.post__statusPostHeader{
    flex: 1 1;
    margin-top: .5rem;
    font-size: 1.4rem;
} 

.bottomWhited{
    border-bottom: none;
    padding-bottom: 0;
}

.feed {
    flex: 1 1;
    max-width: 100vw;
    max-height: 100vh; 
    position:relative;
    margin-right: 20px;
    margin-left: 20px;
    overflow-y: scroll;
    scrollbar-width: none;
    scroll-behavior: smooth;
    /* border-right: 1px solid var(--twittie-background); */
}


.feed ::-webkit-scrollbar {
    display: none;
  }
.feed__header{
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 100;
    border-bottom: 1px solid var(--twittie-background);
    padding: 1.5rem 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 1px;
}

.feed__header-ava{
    margin-right: 2rem;
}

.feed__header > h2 {
    font-size: 2rem;
    flex: 1 1;
    font-weight: 800;
}

.feed__loader{
    width: 100%;
    padding-top: 2rem;
    display: flex;
    justify-content: center !important;
}

/* hide scrollbar for chrome, safari, and opera */
.feed::-webkit-scrollbar{
    display: none;
}

.profile__header{
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 100;
    border-bottom: 1px solid var(--twittie-background);
    display: flex;
    flex-direction: row;
    align-items: center;
    /* margin-right: 1px; */
    padding: .7rem 1rem;
}

.forFollow{ 
    border-bottom: 1px solid white;
}

.profile__backArrow{
    padding: .65rem;
    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 1rem;
    cursor: pointer;
}

.profile__backArrow:hover{
    background: var(--twittie-hover);
}

.profile__header .MuiSvgIcon-root{
    font-size: 2.5rem;
    color: var(--twittie-color);
}
.feed__header-ava > .MuiSvgIcon-root {
    font-size: 2.4rem;
    /* padding: 1.4rem 1.4rem 1.4rem 2rem; */
}

.profile__title{
    display: flex;
    flex-direction: column;
}

.profile__title_title{
    display: flex;
    flex-direction: row;
    align-items: center;    
}

.profile__header h2{
    font-size: 1.9rem;
    font-weight: 800;
    margin-right: 1rem !important;
}

.profile__header span{
    font-size: 1.3rem;
    color: var(--twittie-light);
}




.status__header{
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 100;
    border-bottom: 1px solid var(--twittie-background);
    display: flex;
    flex-direction: row;
    align-items: center;
    /* margin-right: 1px; */
    padding: .7rem 1rem;
}


.status__backArrow{
    padding: .65rem;
    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 1rem;
    cursor: pointer;
}

.status__backArrow:hover{
    background: var(--twittie-hover);
}

.status__header .MuiSvgIcon-root{
    font-size: 2.5rem;
    color: var(--twittie-color);
}

.status__title{
    display: flex;
    flex-direction: column;
}

.status__title_title{
    display: flex;
    flex-direction: row;
    align-items: center;    
}

.status__header h2{
    font-size: 1.9rem;
    font-weight: 800;
    margin-right: 1rem !important;
}

.status__header span{
    font-size: 1.3rem;
    color: var(--twittie-light);
}



@media screen and (max-width: 1282px){
    .feed {
        flex: 1 1 !important;

    }
}

@media screen and (max-width: 500px){
    .feed {
        max-width: 90vw;
    }
    .feed__header {
        max-width: 100vw;
    }
}

.top__logo{
    height:40px;
    width:40px;
    border-radius: 50%;
    margin-left: 20px;
    margin-right: 20px;
}


/* @media screen and (min-width: 1282px){
    .feed__header-ava{
        display: none;
    }
} */
.commonSearch__search{
    display: flex;
    align-items: center;
    height: 3.9rem;
    padding: .8rem;
    border-bottom: 1px solid var(--twittie-background);
}

.commonSearch__searchContainer {
    display: flex;
    align-items: center;
    width: 100%;
    height: 4rem;
    border-radius: 2rem;
    background-color: var(--twittie-background);
    border: 1px solid var(--twittie-background);
}

.commonSearch__searchContainer.pressed{
    border: 1px solid var(--twittie-color) !important;
    background-color: white !important;
}

.commonSearch__searchContainer >input{
    border: transparent;
    line-height: 2.8rem;
    outline: none;
    width: calc(100% - 7rem);
    background: var(--twittie-background);
}

.commonSearch__searchContainer >input.inputPressed{
    background: white !important;    
}

.commonSearch__searchContainer > .MuiSvgIcon-root {
    color: var(--twittie-light);
    padding: .5rem;
    cursor: pointer;
}

.commonSearch__searchContainer.pressed > .MuiSvgIcon-root{
    color: var(--twittie-color);
}

.commonSearch__chats{
    background-color: white;
    overflow: hidden;
    flex: 1 1;
}


@media screen and (max-width: 500px){
    .commonSearch__search {
        max-width: 90vw;
    }
}


/* .announcement-card-section {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: center;
    margin: 0 auto;
    padding-left: 100px;
    padding-right: 100px;
    overflow-x: auto; 
    white-space: nowrap; 
    scrollbar-width: none; 
    -ms-overflow-style: none; 
    margin: 20px;

} */

.announcement-card-section{
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: center;
    overflow-x: auto; 
    white-space: nowrap; 
    scrollbar-width: none; 
    -ms-overflow-style: none; 
    margin: 20px;

}


.announcement-card-section::-webkit-scrollbar {
    display: none; /* Hide the scrollbar for WebKit browsers (Chrome, Safari, etc.) */
}


/* .card-container {
    overflow-x: auto; 
    white-space: nowrap; 
} */

/* .card-section {
    display: inline-block; 
    vertical-align: top; 
} */



  /* Large devices (lg) */
  @media only screen and (min-width: 1200px) {
    .announcement-card-section{
        /* Your styles for large devices here */
        max-width: 712px;
    }
}

/* Medium devices (md) */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .announcement-card-section{
        /* Your styles for medium devices here */
        max-width: 712px;


    }
}

/* Small devices (sm) */
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .announcement-card-section{
        /* Your styles for small devices here */
        max-width: 850px;

    }
}

/* Extra small devices (xs) */
@media only screen and (max-width: 767px) {
    .announcement-card-section{
        /* Your styles for extra small devices here */
        max-width: 630px;

    }
}




.post {
    display: flex;
    align-items: flex-start;
    padding-bottom: 1rem;
}


.post__body{
    flex: 1 1;
    padding: 1rem;
}



.post__headerDescription {
    margin-bottom: 2rem;
    font-size: 1.5rem;
    cursor: pointer;
}

.post__headerDescription>p{
    line-height: 2.1rem;
}

.post__headerText{
    display: flex;
    flex-direction: row;
}

.post__headerText>h3{
    flex: 1 1;
    font-size: 1.5rem;
    margin: .8rem 0 1rem;
}

.post__badge{
    font-size: 1.4rem !important;
    color: var(--twittie-color);
}


.post__headerSpecial{
    font-weight: 600;
    font-size: 1.2rem;
    color: gray;
}

.post__avatar{
    padding: 2rem;
}

.post__footer{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 1.1rem 0 0 0;
}

.post__headerExpandIcon{
    padding: 1rem;
    border-radius: 50%;
}

.post__headerExpandIcon:hover{
    background: #e8f5fe;
    color: var(--twittie-color);
}

.post__expandList{
    list-style: none;
    padding: 0 0 0 0;
    border-radius: 2rem;
}

.post__expandList li{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1.3rem;   
    padding: 1.2rem; 
    background: white;
    cursor: pointer;
    min-width: 20rem;
}

.post__expandList li:first-child{
    border-radius: 1rem 1rem 0 0 !important;
}

.post__expandList li:last-child{
    border-radius: 0rem 0rem 1rem 1rem !important;
}

.post__expandList li:hover{
    background: var(--twittie-background-2) !important;
}

.post__expandList li>h3{
    font-weight: 400 !important;
}

.post__expandList>li>div{
    margin-right: 1rem;
}

.post__expandList>li>div .MuiSvgIcon-root{
    font-size: 1.8rem;
    padding: .5rem 0rem 0rem 0rem;
}

.post__replyingTo {
    font-size: 1.5rem;
    color: var(--twittie-light);
}

.post__replyingTo>a{
    color: var(--twittie-color)
}

.post__replyingTo>a:hover{
    text-decoration: underline !important;
}


.delete{
    color: red;
}

.tweetBox__input-actions .MuiPopover-paper{
    box-shadow: rgba(101, 119, 134, 0.2) 0px 0px 15px, rgba(101, 119, 134, 0.15) 0px 0px 3px 1px !important;
}

.post__statusPostHeader{
    flex: 1 1;
    margin-top: .5rem;
    font-size: 1.4rem;
} 

.bottomWhited{
    border-bottom: none;
    padding-bottom: 0;
}

.announcement-card-container {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    width: 30%;
    border-radius: 5px;
  margin:10px ;
    -webkit-animation:scaledown 0.3s linear;
    animation:scaledown 0.2s linear;
    transform-origin:50% 50%;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
  background-color: white;
  /* border: 2px solid linear-gradient(to right, #1565C0, #b92b27); */

  border-width: 2px; /* Set border width */
  border-style: solid; /* Set border style */
  border-image: linear-gradient(to right, #2193b0, #6dd5ed); /* Define linear gradient */
  border-image-slice: 1; /* Make the entire border to be replaced by the gradient */
  border-radius: 10px; /* Add border-radius for rounded corners */
  padding: 20px; /* Add padding to content */

}



/* background: #b92b27;  
background: -webkit-linear-gradient(to right, #1565C0, #b92b27); 
background: linear-gradient(to right, #1565C0, #b92b27); 
 */

.announcement-card-container:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    /* z-index:100; */
    -webkit-animation:scale 0.3s linear;
    animation:scale 0.1s linear;
    transform-origin:50% 50%;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    background: #F5F8FA;

}
/* For LG viewport size (1200px and above) */
@media screen and (min-width: 1200px) {
    .announcement-card-container {
        width: 30%;
    }
}

/* For MD viewport size (992px - 1199px) */
@media screen and (min-width: 992px) and (max-width: 1199px) {
    .announcement-card-container {
        width: 80%;
    }
}

/* For SM viewport size (768px - 991px) */
@media screen and (min-width: 768px) and (max-width: 991px) {
    .announcement-card-container {
        width: 40%;
    }
}

/* For XS viewport size (less than 768px) */
@media screen and (max-width: 767px) {
    .announcement-card-container {
        width: 100%;
    }
}

.post__body> img{
    border-radius: 2rem;
    width: 100%;
    height: auto;
    cursor: pointer;    

}


/* .card-image {
    border-radius: 5px 5px 0 0;
    height: 250px;
    width: 250px;
    object-fit: cover;
} */



  .stories-container {
    max-width: 600px;
    margin: 24px auto;
    position: relative;
  }
  
  .stories-container .previous-btn,
  .stories-container .next-btn,
  .stories-full-view .previous-btn,
  .stories-full-view .next-btn {
    width: 30px;
    position: absolute;
    z-index: 2;
    top: 50%;
    transform: translateY(-50%);
    background: #444;
    color: #fff;
    border-radius: 50%;
    padding: 10px;
    display: flex;
    cursor: pointer;
  }
  
  .stories-container .previous-btn,
  .stories-container .next-btn {
    opacity: 0;
    pointer-events: none;
    transition: all 400ms ease;
  }
  
  .stories-container .previous-btn.active,
  .stories-container .next-btn.active {
    opacity: 1;
    pointer-events: auto;
  }
  
  .stories-container .previous-btn,
  .stories-full-view .previous-btn {
    left: 8px;
  }
  
  .stories-container .next-btn,
  .stories-full-view .next-btn {
    right: 8px;
  }
  
  .stories-container .story {
    height: 180px;
    width: 120px;
    flex-shrink: 0;
    border-radius: 16px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
  }
  
  .stories-container .story img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .stories-container .stories {
    display: flex;
    grid-gap: 8px;
    gap: 8px;
  }
  
  .stories-container .content {
    overflow-x: scroll;
    scrollbar-width: none;
    scroll-behavior: smooth;
  }
  
  
  .stories-container .content::-webkit-scrollbar {
    display: none;
  }

  
  .stories-container .author {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 8px 16px;
    font-family: "Roboto", sans-serif;
 /* font-size: 15px;
    font-weight: bold; */

    font-weight: 600;
    font-size: 1.2rem;
    color: #fff;
    background: linear-gradient(transparent, #222 130%);
  }
  
  .post__badge{
    font-size: 1.4rem !important;
    color: var(--twittie-color);
}



  .stories-full-view {
    position: fixed;
    inset: 0;
    z-index: 5;
    background: rgba(0, 0, 0, 0.9);
    display: none;
    place-items: center;
  }
  
  .stories-full-view.active {
    display: grid;
  }
  
  .stories-full-view .close-btn {
    position: absolute;
    top: 16px;
    left: 16px;
    width: 30px;
    background: #444;
    color: #fff;
    border-radius: 50%;
    padding: 10px;
    display: flex;
    z-index: 20;
    cursor: pointer;
  }
  
  .stories-full-view .content {
    height: 90vh;
    width: 100%;
    max-width: 700px;
    position: relative;
  }
  
  .stories-full-view .story {
    height: 100%;
    text-align: center;
  }
  
  .stories-full-view .story img {
    height: 100%;
    aspect-ratio: 10/16;
    object-fit: cover;
    border-radius: 16px;
  }
  
  .stories-full-view .author {
    position: absolute;
    top: 8px;
    left: 50%;
    transform: translateX(-50%);
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    background: rgba(0, 0, 0, 0.6);
    color: #fff;
    padding: 4px 32px;
    border-radius: 8px;
  }
.userItemFollow--user__item{
    padding: 1rem ;
    display: flex ;
    flex-direction: row ;
    align-items: center ;
    cursor: pointer ;
}

.userItemFollow--user__item:hover{
    background: var(--twittie-background-2) ;
}

.userItemFollow--user__details{
    margin-left: 1rem ;
    flex: 1 1;
    display: flex ;
    flex-direction: column ;
    justify-content: center ;
}

.userItemFollow--user__details>span{
    font-size: 1.1rem ;
    color: var(--twittie-light) ;
}




.userItemFollow--user__item .MuiButton-root{
    border: 1px solid var(--twittie-darker-color);
    border-radius: 2rem;
    color: var(--twittie-darker-color);
    font-weight: 600;
    text-transform: unset;
    font-size: 1.2rem;
    height: 2.8rem;
    width: 7rem;
}

.userItemFollow--user__item  .MuiAvatar-img{
    width: 5rem;
    height: 5rem;
}

.userItemFollow--user__item .MuiAvatar-root{
    margin-right: 1.5rem;
}
/* .widgets__trendsContainer .MuiButton-root{
    border: 1px solid var(--twittie-darker-color);
    border-radius: 2rem;
    color: var(--twittie-darker-color);
    font-weight: 600;
    text-transform: unset;
    font-size: 1.2rem;
    height: 2.8rem;
    width: 7rem;
}

.widgets__trendsContainer  .MuiAvatar-img{
    width: 5rem;
    height: 5rem;
}

.widgets__trendsContainer .MuiAvatar-root{
    margin-right: 1.5rem;
} */


.searchWidget__wrapper{
    padding:1rem 1rem .7rem 2rem; 
    position: -webkit-sticky; 
    position: sticky;
    top: 0;
    z-index: 100;
    background: white;
}

.searchWidget{
    display: flex;
    align-items: center;
    background-color: var(--twittie-background);
    padding: 1rem;
    border-radius: 2rem;
    border: 1px solid var(--twittie-background);
}

.searchWidget .MuiSvgIcon-root{
    color: var(--twittie-light);
}

.searchWidget.beingPressed{
    border: 1px solid var(--twittie-color);
    background: white;
}

.searchWidget > input {
    border: 1px solid var(--twittie-background);
    outline: none;
    width: 100%;
    background-color: var(--twittie-background);
}

.searchWidget.beingPressed>input{
    background-color: white;
    border:1px solid white;
}

.searchWidget.beingPressed .MuiSvgIcon-root{
    color: var(--twittie-color)
}

.widgets{
    /* flex: 0.3; */
    flex: 0.5 1;

    overflow-y: scroll;
    -ms-overflow-style: none; /*ie, edge*/
    scrollbar-width: none; /*firefox*/

}

.widgets::-webkit-scrollbar{
    display: none;
}

.widgets__widgetContainer {
    margin: .7rem 1rem 1.5rem 2rem;
    padding: 2rem;
    background-color: var(--twittie-background-2);
    border-radius: 2rem;
}

@media screen and (max-width: 1000px){
   .widgets{
       display: none;
   } 
}


.user__item{
    padding: 1rem;
    display: flex;
    flex-direction: row;
    cursor: pointer;
}

.nocursor{
    cursor: unset !important;
}

.user__details{
    margin-left: 1rem;
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 1rem;
}

.user__details>span{
    font-size: 1.1rem;
    color: var(--twittie-light);
}

.user__item .MuiSvgIcon-root{
    padding: 1rem;
    color: var(--twittie-color);
}

.searchUser{
    width: 100%;
}

.searchUser__header{
    background: var(--twittie-background);
    padding-bottom: 1rem;
    padding: 0 1rem 1rem;
}
.searchUser__header-search{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1rem 0 0rem 1rem;
}

.searchUser__header-search .MuiSvgIcon-root{
    font-size: 2.5rem;
    color: var(--twittie-color);
}
.searchUser__header-search>input{
    flex: 1 1;
    border: none;
    outline: none;
    line-height: 2rem;
    font-size: 1.6rem;
    padding: 1rem 0 1rem 1rem;
    background: var(--twittie-background);
    color: var(--twittie-light);
}

.searchUser__header .MuiChip-clickable{
    background: white;
    font-size: 1.6rem;
    margin-right: 1rem;
}

.searchUser__header .MuiChip-deleteIcon{
    color: var(--twittie-color);
}

.searchUser__header .MuiChip-clickable:hover{
    background: var(--twittie-background-2);
}

.MuiLinearProgress-colorPrimary {
    background-color: white !important;
}

.MuiLinearProgress-barColorPrimary{
    background-color: var(--twittie-color) !important;
}

.userrs__result{
    width: 100%;
}

.searchUser__header .MuiChip-root{
    margin-bottom: .4rem;
}

.chips{
    padding-top: 1rem;
}
.sidebarChatOption{
    display: flex;
    /* flex-direction: row; */
    padding: 1.5rem;
    cursor: pointer;
    border-bottom: 1px solid #f6f6f6;
}

a{
    text-decoration: none!important;
    color: black;
}

.sidebarChatOption:hover{
    background-color: var(--twittie-background-2)
}

.sidebarChatOption__info > h2 {
    font-size: 1.5rem;
}

.sidebarChatOption__info{
    font-size: 1.5rem;
    flex: 1 1;
    margin-left: 1.5rem;
}

.sidebarChatOption__info span{
    color: var(--twittie-light);
    font-weight: 400;
    margin-left: .5rem;
}
.sidebarChatOption__info >p{
    color: var(--twittie-light)
}

.sidebarChatOption__date{
    font-size: 1.5rem;    
    padding-right: .1rem;
    color: var(--twittie-light)
}

.sidebarChatOption .MuiAvatar-root{
    width: 5rem;
    height: 5rem;
}


.active_chat{
    border-right: 2px solid var(--twittie-color);
}
.sidebarChat{
    flex: 2 1;
    border-right: 1px solid var(--twittie-background);
}

.noned{
    display: none;
}

.sidebarChat__header{
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 100;
    border-bottom: 1px solid var(--twittie-background);
    padding: 1.5rem 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;    
}

.sidebarChat__header>h2{
    font-size: 2rem;
    flex: 1 1;
    font-weight: 800;
}

.sidebarChat .MuiSvgIcon-root{
    font-size: 2.5rem;
    color: var(--twittie-color);
    margin-left: 1rem;
    margin-right: .1rem;
    cursor: pointer;

}



.sidebarChat__search{
    display: flex;
    align-items: center;
    height: 3.9rem;
    padding: .8rem;
    border-bottom: 1px solid var(--twittie-background);
}

.sidebarChat__searchContainer {
    display: flex;
    align-items: center;
    width: 100%;
    height: 4rem;
    border-radius: 2rem;
    background-color: var(--twittie-background);
    border: 1px solid var(--twittie-background);
}

.sidebarChat__searchContainer.pressed{
    border: 1px solid var(--twittie-color) !important;
    background-color: white !important;
}

.sidebarChat__searchContainer >input{
    border: transparent;
    line-height: 2.8rem;
    outline: none;
    width: calc(100% - 7rem);
    background: var(--twittie-background);
}

.sidebarChat__searchContainer >input.inputPressed{
    background: white !important;    
}

.sidebarChat__searchContainer > .MuiSvgIcon-root {
    color: var(--twittie-light);
    padding: .5rem;
    cursor: pointer;
}

.sidebarChat__searchContainer.pressed > .MuiSvgIcon-root{
    color: var(--twittie-color);
}

.sidebarChat__chats{
    background-color: white;
    overflow: hidden;
    flex: 1 1;
}

.chat{
    flex: 3 1;
    display: flex;
    flex-direction: column;
    border-right:1px solid var(--twittie-background);
}

.chat__header{
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 100;
    border-bottom: 1px solid var(--twittie-background);
    padding: 1rem 2rem 1rem 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 3.8rem;
}

.chat__header>h2{
    font-size: 2rem;
    flex: 1 1;
    font-weight: 800;
    margin-right:2rem
}

.chat__header-ava{
    margin-right: 2rem;
}

.chat__header-ava .MuiAvatar-root{
    margin: -2rem 0rem -2rem 0rem;
}

.chat .MuiSvgIcon-root{
    font-size: 2.5rem;
    color: var(--twittie-color);
}

.chat__backArrow{
    padding: .65rem;
    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 1rem;
    cursor: pointer;
}

.chat__backArrow:hover{
    background: var(--twittie-background-2);
}

.chat__message {
    position: relative;
    font-size: 1.6rem;
    padding: .5rem;
    background-color: var(--twittie-background-2);
    border-radius: 0 2rem 2rem 0;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-bottom: .3rem;
    max-width: 74%;
    display: flex;
    flex-direction: column;
}

.chat__message img{
    border-radius: 0 2rem 2rem 0;
    object-fit: cover;
    max-width: 42.5rem;
    width: 100%;
    cursor: pointer;
}

.chat__message>span{
    padding: 0.5rem 0;
    min-width: 2.3rem;
}

.chat__item{
    margin-bottom: 1rem;
    word-break: break-all
}

.chat__receiver{
    margin-left: auto;
    background-color: var(--twittie-color);
    color: white;
    border-radius: 2rem .2rem .2rem 2rem;
}

.chat__receiver>img{
    border-radius: 2rem .2rem .2rem 2rem !important;
}

.chat__receiver span{
    text-align: end;
}

.chat__timestamp{
    font-size: xx-small;
    text-align: end;
}

.lefted{
    text-align: start !important ;
}

.chat__name {
    position: absolute;
    top: -1.5rem;
    font-weight: 800;
    font-size: xx-small;
}

.chat__headerRight{
    display: flex;
    justify-content: space-between;
    min-width: 10rem;
}

.chat__body{
    flex: 1 1;
    background-repeat: repeat;
    background-position: center;
    padding: 1rem;
    overflow-y: scroll;
    -ms-overflow-style: none; /*ie, edge*/
    scrollbar-width: none; /*firefox*/
}

.chat__body::-webkit-scrollbar{
    display: none;
}

.chat__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 6.2rem;
    border-top: 1px solid var(--twittie-background);
    padding: 0 1rem 0 .6rem;
}

.chat__footer-ImageBox{
    position: absolute;
    bottom: 7rem;
    border-radius: 2rem;
    /* box-shadow: rgba(101, 119, 134, 0.2) 0px 0px 15px, rgba(101, 119, 134, 0.15) 0px 0px 3px 1px !important; */
    width: 19rem;
    height: 23rem;
    background: var(--twittie-background);
}

.chat__footer-ImageBox>img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 2rem;
}

.chat__footer > form {
    flex: 1 1;
    display: flex;
    align-items: center;
}

.chat__footer>form>input{
    flex: 1 1;
    padding: 0.5rem 1rem;
    /* margin: 0 0 0 1rem; */
    border: .2rem solid var(--twittie-color);
    line-height: 3rem;
    font-size: 1.5rem;
    border-right: none;
    outline: none;
    border-radius: 2rem 0 0 2rem;
}

.chat__footer>form>button{
    display: none;
}

.chat__footer .MuiSvgIcon-root{
    font-size: 3rem !important;
    cursor: pointer;
}

.chat__footer-emowrapper {
    padding: .4rem 1rem .3rem 0rem;
    border: .2rem solid var(--twittie-color);
    border-left: none;
    border-radius: 0 2rem 2rem 0;
    margin-left: -.1rem;
    margin-right: 1rem;
}

.readyBtn{
    color: rgba(29,161,242,1.00) !important;
}

.spinnerSpan{
    font-size: 2rem;
    color: var(--twittie-color);
}


@media screen and (max-width: 500px){
    .chat{
        flex: 1 1;
        height: calc(100vh - 4.5rem);
    }
    .chat__footer>form>input{
        width: calc(100% - 1rem );
    }

    .chat__footer-ImageBox {
        bottom: 11rem;
    }
}

@media screen and (min-width: 914px){
    .chat__backArrow{
        display: none;
    }
}
.conversationInfo{
    flex: 3 1;
    display: flex;
    flex-direction: column;
    border-right:1px solid var(--twittie-background);
}

.conversationInfo__header{
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 100;
    border-bottom: 1px solid var(--twittie-background);
    padding: 1rem 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;    
}

.conversationInfo__header>h2{
    font-size: 2rem;
    flex: 1 1;
    font-weight: 800;
    margin-right:2rem
}

.conversationInfo__header--back{
    padding: .65rem;
    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 1rem;
    cursor: pointer;
}

.conversationInfo__header--back:hover{
    background: var(--twittie-background-2);
}

.conversationInfo__header .MuiSvgIcon-root {
    font-size: 2.5rem !important;
    color: var(--twittie-color);
}

.conversationInfo--user__item{
    padding: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    border-bottom: 10px solid var(--twittie-background);
}

.conversationInfo--user__item:hover{
    background: var(--twittie-background-2);
}

.conversationInfo--user__details{
    margin-left: 1rem;
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.conversationInfo--user__details>span{
    font-size: 1.1rem;
    color: var(--twittie-light);
}

.conversationInfo .MuiButton-root{
    text-transform: unset;
    font-size: 1.3rem;
    color: var(--twittie-color) !important;
    border-radius: 2rem;
    border: 1px solid var(--twittie-color);
    padding: 0rem 1rem ;
    max-height: 2.7rem;
}

.conversationInfo .MuiButton-root:hover{
    background: var(--twittie-hover);
}

.notifications{
    border-top: 10px solid var(--twittie-background);
}
.notifications>h2{
    font-size: 1.6rem;
    flex: 1 1;
    font-weight: 800;
    padding:  1rem;
    border-bottom: 1px solid var(--twittie-background);
}

.mute{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1.3rem;
    padding: 0 1rem;
    border-bottom: 10px solid var(--twittie-background);
    justify-content: space-between ;    
}

.notifications .MuiSwitch-root{
    margin: 0.3rem -1rem 0.3rem 0;
}

.notifications .MuiSwitch-input{
    left: -100%;
}

.notifications .MuiSwitch-colorPrimary.Mui-checked{
    color: var(--twittie-darker-color);
}

.notifications .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track{
    background-color: var(--twittie-color) !important;
}

.userActions{
    padding: 0;
    list-style-type: none;
}

.userActions>li{
    font-size: 1.3rem !important;
    text-align: center;
    font-weight: 450;
    padding: 1rem;
    color: var(--twittie-color);
    cursor: pointer;
    border-top: 1px solid var(--twittie-background);
}

.userActions>li:hover{
    background: var(--twittie-hover);
}

.red{
    color: var(--twittie-red) !important;
}

.red:hover{
    background: #e0245e0d !important;
}

.login__section{
    position: relative;
    min-height: 100vh;
    background: var(--twittie-background);
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: 2rem; */

    width: 100vw; 
}

.login__section .login__container{
    position: relative;
    width: 100%;
    height: 100%;
    background: #fff;
    /* box-shadow: 0 1.5rem 5rem rgba(0,0,0,0.1); */
    overflow: hidden;
}

.login__section .login__container .user{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
}

.login__section .login__container .user .imgBc{
    position: relative;
    width: 100%;
    height: 100%;
    transition: .5s;
}


.login__section .login__container .user .imgBc img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
}

.login__section .login__container .user .formBc{
    position: relative;
    width: 100%;
    height: 100%;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 4rem;
    transition: .5s;
}




.login__section .login__container .user .formBc form h2{
    font-size: 1.8rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: .2rem;
    text-align: center;
    width: 100%;
    margin-bottom: 1rem;
    color: var(--twiitie-light);
}

.login__section .login__container .user .formBc form input{
    position: relative;
    width: -webkit-fill-available;
    padding: 1rem 2rem;
    background: var(--twittie-background-2);
    color: #333;
    border: none;
    outline: none;
    box-shadow: none;
    margin: .8rem 0;
    font-size: 1.4rem;
    letter-spacing: .1rem;
    font-weight: 300;
    border-radius: 2rem;
}

.login__section .login__container .user .formBc form button{
    position: relative;
    width: -webkit-fill-available;
    padding: 1rem 2rem;
    background: var(--twittie-background-2);
    color: #333;
    border: none;
    outline: none;
    box-shadow: none;
    margin: .8rem 0;
    font-size: 1.4rem;
    letter-spacing: .1rem;
    font-weight: 300;
    border-radius: 2rem;    


    max-width: 10rem;
    background: var(--twittie-color);
    color: white;
    cursor: pointer;
    font-size: 1.4rem;
    font-weight: 500;
    letter-spacing: .1rem;
    transition: .5s;
    border-radius: 2rem;


}


.login__section .login__container .user .formBc form input[type='submit']{
    max-width: 10rem;
    background: var(--twittie-color);
    color: white;
    cursor: pointer;
    font-size: 1.4rem;
    font-weight: 500;
    letter-spacing: .1rem;
    transition: .5s;
    border-radius: 2rem;
}


.login__section .login__container .user .formBc form .signup{
    position: relative;
    margin-top: 2rem;
    font-size: 1.2rem;
    letter-spacing: .1rem;
    color: #555;
    text-transform: uppercase;
    font-weight: 300;
}


.login__section .login__container .user .formBc form .signup span{
    font-weight: 600;
    text-decoration: none;

}

.login__section .login__container .signupBc {
    pointer-events: none;
}

.login__section .login__container.active .signupBc {
    pointer-events: initial;
}

.login__section .login__container .signupBc .formBc{
    left: 100%;
}

.login__section .login__container.active .signupBc .formBc{
    left: 0;
}

.login__section .login__container .signupBc .imgBc{
    left: -100%;
}

.login__section .login__container.active .signupBc .imgBc{
    left: 0;
}

.error{
    position: relative;
    width: -webkit-fill-available;
    padding: 1rem 2rem;
    color: red;
    border: 0.2rem solid red;
    outline: none;
    box-shadow: none;
    margin: .8rem 0;
    font-size: 1.4rem;
    letter-spacing: .1rem;
    font-weight: 300;
    border-radius: 2rem;  
}


@media (max-width: 991px){
    .login__section .login__container .imgBc{
        max-width: 40rem;
    }
}
/* * {
    margin: 0;
    padding: 0;
    outline: none;
    font-family: "Poppins", sans-serif;
} */
:root {
    --primary: #333;
    --secondary: #333;
    --errorColor: red;
    --stepNumber: 6;
    --containerWidth: 600px;
    --bgColor: #333;
    --inputBorderColor: lightgray;
    --stepActive: #0000FF;

}


/* ======================================================================================================== */
/* ======================================================================================================== */
/* ======================================================================================================== */
/* ======================================================================================================== */



.multi-form-signup-section{
    position: relative;
    min-height: 100vh;
    background: var(--twittie-background);
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: 2rem; */

    width: 100vw; 
}

.multi-form-signup-section .multi-form-signup-container{
    position: relative;
    width: 100%;
    height: 100%;
    background: #fff;
    /* box-shadow: 0 1.5rem 5rem rgba(0,0,0,0.1); */
    overflow: hidden;
}

.multi-form-signup-section .multi-form-signup-container .user{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
}

.multi-form-signup-section .multi-form-signup-container .user .imgBc{
    position: relative;
    width: 80%;
    height: 100%;
    background: #ff0;
    transition: .5s;
}


.multi-form-signup-section .multi-form-signup-container .user .imgBc img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
}

.multi-form-signup-section .multi-form-signup-container .user .formBc{
    position: relative;
    width: 50%;
    height: 100%;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 4rem;
    transition: .5s;
}

.multi-form-signup-section .multi-form-signup-container .user .formBc form h2{
    font-size: 1.8rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: .2rem;
    text-align: center;
    width: 100%;
    margin-bottom: 1rem;
    color: var(--twiitie-light);
}

.multi-form-signup-section .multi-form-signup-container .user .formBc form input{
    position: relative;
    width: -webkit-fill-available;
    padding: 1rem 2rem;
    background: var(--twittie-background-2);
    color: #333;
    border: none;
    outline: none;
    box-shadow: none;
    margin: .8rem 0;
    font-size: 1.4rem;
    letter-spacing: .1rem;
    font-weight: 300;
    border-radius: 2rem;
}

.multi-form-signup-section .multi-form-signup-container .user .formBc form button{
    position: relative;
    width: -webkit-fill-available;
    padding: 1rem 2rem;
    background: var(--twittie-background-2);
    color: #333;
    border: none;
    outline: none;
    box-shadow: none;
    margin: .8rem 0;
    font-size: 1.4rem;
    letter-spacing: .1rem;
    font-weight: 300;
    border-radius: 2rem;    


    max-width: 10rem;
    background: var(--twittie-color);
    color: white;
    cursor: pointer;
    font-size: 1.4rem;
    font-weight: 500;
    letter-spacing: .1rem;
    transition: .5s;
    border-radius: 2rem;


}


.multi-form-signup-section .multi-form-signup-container .user .formBc form input[type='submit']{
    max-width: 10rem;
    background: var(--twittie-color);
    color: white;
    cursor: pointer;
    font-size: 1.4rem;
    font-weight: 500;
    letter-spacing: .1rem;
    transition: .5s;
    border-radius: 2rem;
}


.multi-form-signup-section .multi-form-signup-container .user .formBc form .signup{
    position: relative;
    margin-top: 2rem;
    font-size: 1.2rem;
    letter-spacing: .1rem;
    color: #555;
    text-transform: uppercase;
    font-weight: 300;
}


.multi-form-signup-section .multi-form-signup-container .user .formBc form .signup span{
    font-weight: 600;
    text-decoration: none;

}

.multi-form-signup-section .multi-form-signup-container .signupBc {
    pointer-events: none;
}

.multi-form-signup-section .multi-form-signup-container.active .signupBc {
    pointer-events: initial;
}

.multi-form-signup-section .multi-form-signup-container .signupBc .formBc{
    left: 100%;
}

.multi-form-signup-section .multi-form-signup-container.active .signupBc .formBc{
    left: 0;
}

.multi-form-signup-section .multi-form-signup-container .signupBc .imgBc{
    left: -100%;
}

.multi-form-signup-section .multi-form-signup-container.active .signupBc .imgBc{
    left: 0;
}



@media (max-width: 991px){
    .multi-form-signup-section .multi-form-signup-container .imgBc{
        max-width: 40rem;
    }
}



/* ======================================================================================================== */
/* ======================================================================================================== */
/* ======================================================================================================== */
/* ======================================================================================================== */
.multi-form-error{
    color:red;
    color:var(--errorColor)
}

.multi-form-body {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    overflow-x: hidden;
    overflow-y: hidden;
}
    

::selection {
    color: #fff;
    background: #333;
    background: var(--primary);
}

.multi-form-container {
    /* width: var(--containerWidth); */
    width:100%;
    overflow:scroll;
    background: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 50px 35px 10px 35px;

    scrollbar-width: thin; /* For Firefox */
    scrollbar-color: #d4d4d4 transparent; /* Custom scrollbar color for Firefox */
}
.multi-form-container ::-webkit-scrollbar {
    /* display: none; */
  }

  
.multi-form-container::-webkit-scrollbar {
    width: 8px; /* Custom scrollbar width for WebKit browsers */
}

.multi-form-container::-webkit-scrollbar-track {
    background: transparent; /* Scrollbar track color */
}

.multi-form-container::-webkit-scrollbar-thumb {
    background-color: #d4d4d4; /* Scrollbar thumb color */
    border-radius: 4px; /* Rounded corners for the scrollbar thumb */
    border: 2px solid transparent; /* Space around the thumb */
    background-clip: content-box; /* Ensures border doesn't overlap thumb */
}

.multi-form-container header {
    font-size: 35px;
    font-weight: 600;
    margin: 0 0 30px 0;
}
.multi-form-container .multi-form-form-outer {
    width: 100%;
    overflow: hidden;
}
.multi-form-container .multi-form-form-outer form {
    display: flex;
    width: calc(100% * 6);
    width: calc(100% * var(--stepNumber));
}

.multi-form-logo {
    width: 100px;
    margin-bottom: 15px;
    border-radius:50%
}

.multi-form-form-outer form .multi-form-page {
    width: calc(100% / 6);
    width: calc(100% / var(--stepNumber));
    transition: margin-left 0.multi-form-3s ease-in-out;
}
.multi-form-form-outer form .multi-form-page .multi-form-title {
    text-align: left;
    font-size: 25px;
    font-weight: 500;
}
.multi-form-form-outer form .multi-form-page .multi-form-field {
    width: 100%;
    height: 45px;
    /* margin: 45px 0; */
    margin-top:45px;
    display: flex;
    position: relative;
}
form .multi-form-page .multi-form-field .multi-form-label {
    position: absolute;
    top: -30px;
    font-weight: 500;
}
form .multi-form-page .multi-form-field input {
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    border: 1px solid lightgray;
    border: 1px solid var(--inputBorderColor);
    border-radius: 5px;
    padding-left: 15px;
    margin: 0 1px;
    font-size: 18px;
    transition: border-color 150ms ease;
    
}
form .multi-form-page .multi-form-field input.multi-form-invalid-input {
    border-color: red;
    border-color: var(--errorColor);
}
form .multi-form-page .multi-form-field select {
    width: 100%;
    padding-left: 10px;
    font-size: 17px;
    font-weight: 500;
}
form .multi-form-page .multi-form-field button {
    width: 100%;
    height: calc(100% + 5px);
    border: none;
    margin-top: -20px;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: 0.multi-form-5s ease;
    background: #0949ab;

}


form .multi-form-page .multi-form-btns button {
    margin-top: -20px !important;
}
form .multi-form-page .multi-form-btns button.multi-form-prev {
    margin-right: 3px;
    font-size: 17px;
    background: #4488f0;

}
form .multi-form-page .multi-form-btns button.multi-form-next {
    margin-left: 3px;

}
form .multi-form-page .multi-form-field button:hover {
    background: #000;
}
.multi-form-container .multi-form-progress-bar {
    display: flex;
    margin: 40px 0;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}
.multi-form-container .multi-form-progress-bar .multi-form-step {
    text-align: center;
    width: 100%;
    position: relative;
}
.multi-form-container .multi-form-progress-bar .multi-form-step p {
    font-weight: 500;
    font-size: 18px;
    color: #000;
    margin-bottom: 8px;
}
.multi-form-progress-bar .multi-form-step .multi-form-bullet {
    height: 25px;
    width: 25px;
    display: inline-block;
    border-radius: 50%;
    position: relative;
    transition: 0.multi-form-2s;
    font-weight: 500;
    font-size: 17px;
    line-height: 25px;
}
.multi-form-progress-bar .multi-form-step .multi-form-bullet.multi-form-active {
    /* border-color: var(--primary);
    background: var(--primary); */
    border-color: #0000FF;
    border-color: var(--stepActive);
    background: #0000FF;
    background: var(--stepActive);

    
}
.multi-form-progress-bar .multi-form-step .multi-form-bullet span {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}
.multi-form-progress-bar .multi-form-step .multi-form-bullet.multi-form-active span {
    display: none;
}
.multi-form-progress-bar .multi-form-step .multi-form-bullet:before,
.multi-form-progress-bar .multi-form-step .multi-form-bullet:after {
    position: absolute;
    content: "";
    bottom: 11px;
    right: -51px;
    height: 3px;
    width: 44px;
    background: #262626;
}
.multi-form-progress-bar .multi-form-step .multi-form-bullet.multi-form-active:after {
    /* background: var(--primary); */
    background: #0000FF;
    background: var(--stepActive);
    transform: scaleX(0);
    transform-origin: left;
    -webkit-animation: animate 0.multi-form-3s linear forwards;
            animation: animate 0.multi-form-3s linear forwards;
}
@-webkit-keyframes animate {
    100% {
        transform: scaleX(1);
    }
}
@keyframes animate {
    100% {
        transform: scaleX(1);
    }
}
.multi-form-progress-bar .multi-form-step:last-child .multi-form-bullet:before,
.multi-form-progress-bar .multi-form-step:last-child .multi-form-bullet:after {
    display: none;
}
.multi-form-progress-bar .multi-form-step p.multi-form-active {
    /* color: var(--primary); */
    color: #0000FF;
    color: var(--stepActive);
    transition: 0.multi-form-2s linear;
}
.multi-form-progress-bar .multi-form-step .multi-form-check {
    position: absolute;
    left: 50%;
    top: 70%;
    font-size: 15px;
    transform: translate(-50%, -50%);
    display: none;
}
.multi-form-progress-bar .multi-form-step .multi-form-check.multi-form-active {
    display: block;
    /* color: #fff; */
    color: #0000FF;
    color: var(--stepActive);
    
}

@media screen and (max-width: 660px) {
    :root {
        --containerWidth: 400px;
    }
    .multi-form-progress-bar .multi-form-step p {
        display: none;
    }
    .multi-form-progress-bar .multi-form-step .multi-form-bullet::after,
    .multi-form-progress-bar .multi-form-step .multi-form-bullet::before {
        display: none;

    }
    .multi-form-progress-bar .multi-form-step .multi-form-bullet {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .multi-form-progress-bar .multi-form-step .multi-form-check {
        position: absolute;
        left: 50%;
        top: 50%;
        font-size: 15px;
        transform: translate(-50%, -50%);
        display: none;
    }
    .multi-form-step {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
@media screen and (max-width: 490px) {
    :root {
        --containerWidth: 100%;
    }
    .multi-form-container {
        box-sizing: border-box;
        border-radius: 0;
    }
}


.multi-form-section{
    position: relative;
    min-height: 100vh;
    background: var(--twittie-background);
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: 2rem; */

    width: 100vw; 
}


.multi-form-section .multi-form-container{
    position: relative;
    width: 100%;
    height: 100%;
    background: #fff;
    /* box-shadow: 0 1.5rem 5rem rgba(0,0,0,0.1); */
    overflow: hidden;
}

.multi-form-section .multi-form-container .user{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
}

.multi-form-section .multi-form-container .user .imgBc{
    position: relative;
    width: 50%;
    height: 100%;
    background: #ff0;
    transition: .5s;
}


.multi-form-section .multi-form-container .user .imgBc img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
}

.multi-form-section .multi-form-container .user .formBc{
    position: relative;
    width: 50%;
    height: 100%;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 4rem;
    transition: .5s;
}

.multi-form-section .multi-form-container .user .formBc form h2{
    font-size: 1.8rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: .2rem;
    text-align: center;
    width: 100%;
    margin-bottom: 1rem;
    color: var(--twiitie-light);
}

.multi-form-section .multi-form-container .user .formBc form input{
    position: relative;
    width: -webkit-fill-available;
    padding: 1rem 2rem;
    background: var(--twittie-background-2);
    color: #333;
    border: none;
    outline: none;
    box-shadow: none;
    margin: .8rem 0;
    font-size: 1.4rem;
    letter-spacing: .1rem;
    font-weight: 300;
    border-radius: 2rem;
}

.multi-form-section .multi-form-container .user .formBc form button{
    position: relative;
    width: -webkit-fill-available;
    padding: 1rem 2rem;
    background: var(--twittie-background-2);
    color: #333;
    border: none;
    outline: none;
    box-shadow: none;
    margin: .8rem 0;
    font-size: 1.4rem;
    letter-spacing: .1rem;
    font-weight: 300;
    border-radius: 2rem;    


    max-width: 10rem;
    background: var(--twittie-color);
    color: white;
    cursor: pointer;
    font-size: 1.4rem;
    font-weight: 500;
    letter-spacing: .1rem;
    transition: .5s;
    border-radius: 2rem;


}


.multi-form-section .multi-form-container .user .formBc form input[type='submit']{
    max-width: 10rem;
    background: var(--twittie-color);
    color: white;
    cursor: pointer;
    font-size: 1.4rem;
    font-weight: 500;
    letter-spacing: .1rem;
    transition: .5s;
    border-radius: 2rem;
}


.multi-form-section .multi-form-container .user .formBc form .signup{
    position: relative;
    margin-top: 2rem;
    font-size: 1.2rem;
    letter-spacing: .1rem;
    color: #555;
    text-transform: uppercase;
    font-weight: 300;
}

.multi-form-section .multi-form-container .user .formBc form .signup span{
    font-weight: 600;
    text-decoration: none;

}

.multi-form-section .multi-form-container .signupBc {
    pointer-events: none;
}

.multi-form-section .multi-form-container.active .signupBc {
    pointer-events: initial;
}

.multi-form-section .multi-form-container .signupBc .formBc{
    left: 100%;
}

.multi-form-section .multi-form-container.active .signupBc .formBc{
    left: 0;
}

.multi-form-section .multi-form-container .signupBc .imgBc{
    left: -100%;
}

.multi-form-section .multi-form-container.active .signupBc .imgBc{
    left: 0;
}

.error{
    position: relative;
    width: -webkit-fill-available;
    padding: 1rem 2rem;
    color: red;
    border: 0.2rem solid red;
    outline: none;
    box-shadow: none;
    margin: .8rem 0;
    font-size: 1.4rem;
    letter-spacing: .1rem;
    font-weight: 300;
    border-radius: 2rem;  
}

@media (max-width: 991px){
    .multi-form-section .multi-form-container .imgBc{
        max-width: 40rem;
    }
}

.signup{
    position: relative;
    margin-top: 2rem;
    font-size: 1.2rem;
    letter-spacing: .1rem;
    /* color: #555; */
    text-transform: uppercase;
    font-weight: 300;
}

.signup span{
    font-weight: 600;
    text-decoration: none;
    /* color: var(----stepActive) */
    color: #0000FF;  
}

.signup span:hover{
    font-weight: 800;
    text-decoration: none;
    color: #0000FF;
}

.feed__loader{
    width: 100%;
    padding-top: 2rem;
    display: flex;
    justify-content: center !important;
}

.multi-form-bullet.bullet-completed {
    color: var(--twittie-color);
    background: var(--twittie-color);
    border: 2px solid #50b7f5;
}

.multi-form-bullet.regular {
    border: 2px solid #000;
}

.multi-form-progress-bar .multi-form-step .multi-form-bullet:before, .multi-form-progress-bar .multi-form-step .multi-form-bullet.bullet-completed:before {
    background:var(--twittie-color);
}

.multi-form-progress-bar .multi-form-step .multi-form-bullet:before, .multi-form-progress-bar .multi-form-step .multi-form-bullet.bullet-completed:after {
    background:var(--twittie-color);
}


/* The alert message box */
.multi-form-alert {
    padding: 20px;
    color: white;
    margin-bottom: 15px;
}
 
.multi-form-alert .error{
    background-color: #f44336; /* Red */

}

.multi-form-alert .success{
    background-color: #0c6624; /* Green */
}
  /* The close button */
.multi-form-closebtn {
    margin-left: 15px;
    color: white;
    font-weight: bold;
    float: right;
    font-size: 22px;
    line-height: 20px;
    cursor: pointer;
    transition: 0.3s;
}
  
  /* When moving the mouse over the close button */
.multi-form-closebtn:hover {
    color: black;
}



/* ======================================================================================================== */
/* ======================================================================================================== */
/* ======================================================================================================== */
/* ======================================================================================================== */
    



    /* xs */
    @media (max-width: 576px) {
        .imgBc{
            display: none;
        }

        .multi-form-signup-section .multi-form-signup-container .user {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

    }
  
    /* sm  */
    @media (min-width: 576px) and (max-width: 768px) {
        .imgBc{
            display: none;
        }

        .multi-form-signup-section .multi-form-signup-container .user {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
  
    /* md  */
    @media (min-width: 768px) and (max-width: 992px) {
    .imgBc{
        display: none;
    }

    .multi-form-container {
        z-index:5;
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    }

    .multi-form-signup-section .multi-form-signup-container .user {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    }
  


.editProfile__theme{
    height: 20rem;
    background-repeat: no-repeat;
    background-size: cover;
    background: var(--twittie-background-2);
}

.editProfile__theme-photoWrapper{
    width: 14rem;
    height: 14rem;
    border-radius: 50%;
    transform: translate(2rem, 13rem);
    background: var(--twittie-background);
}

.editProfile .MuiSvgIcon-root{
    font-size: 2.75rem !important;
    color: white;
}

.editProfile__theme-themeActions{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;    

    position: relative;
    top: -40%;

}

.editProfile__theme-themeActions>div{
    width: 3rem;
    height: 3rem;
    padding: 1rem;
    border-radius: 50%;
    opacity: 0.6;
    background: var(--twittie-background); 
    margin: 1rem;
}

.editProfile__theme-themeActions>div:hover{
    opacity: 0.3;
}

.editProfile__theme-photoWrapper>img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    border: 4px solid white;
}

.editProfile__theme-photoWrapper>div{
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 1rem;
    border-radius: 50%;
    transform: translate(-45%, -45%);
    cursor: pointer;
}

.editProfile__theme-photoWrapper>div:hover{
    background: var(--twittie-background);
    opacity: 0.6;
}

.editProfile form{
    margin-top: 9rem;
    width: 100%;
}

.editProfile .MuiFormControl-root{
    width: 100%;
}

.editProfile .MuiFilledInput-input{
    font-size: 1.5rem
}

.editProfile .MuiInputLabel-filled.MuiInputLabel-shrink{
    padding: 0 2.6rem;
}

.editProfile .MuiInputLabel-filled{
    font-size: 1.6rem;
    font-weight: 700;
    padding: 0 2rem;
}

.editProfile .MuiFormLabel-root.Mui-focused{
    color: var(--twittie-color) !important;
}

.editProfile .MuiFilledInput-root{
    background-color: var(--twittie-background-2) !important;
    padding: 0 2rem;
}

.editProfile .MuiFormControl-root:nth-child(3){
    border-radius: 0 0 2rem 2rem;
}

.editProfile .MuiFilledInput-underline:before{
    border-bottom: none 
}

.editProfile .MuiFilledInput-underline:after{
    border-bottom: 3px solid var(--twittie-color);
}

/* .cropImageInEditProfile{
   width: 100%; 
   height: 100%;
}

.cropImageInEditProfile img {
    width: 100%; 
    height: auto;
    max-height: 60vh !important;     
} */


.userProfile{
    width: 100%;
    display: flex;
    flex-direction: column;
}

.userProfile__theme{
    height: 20rem;
    background-repeat: no-repeat;
    background-size: cover;
    background: var(--twittie-background-2);
}

.photoWrapper{
    width: 14rem;
    height: 14rem;
    border-radius: 50%;
    transform: translate(2rem, 13rem);
    background: var(--twittie-background);
}

.photoWrapper>img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    border: 4px solid white;
    cursor: pointer;
}

.infoWrapper{
    padding: 1rem 1rem 1rem 2rem;
}

.userProfile__actions{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1.5rem;
    font-weight: 700;
    color: var(--twittie-color);
    margin-bottom: 3.9rem;
}

.userProfile__actions>div{
    padding: .5rem 1.5rem;
    border: 1px solid var(--twittie-color);
    border-radius: 2rem;
}

.userProfile__actions>div:hover{
    background: var(--twittie-hover);
    cursor: pointer;
}

.moreWrapper{
    margin-left: auto;
    padding: .7rem .8rem .3rem !important;
}

.mailWrapper{
    padding: .5rem .8rem !important;
    margin-left: 1rem !important;
}

.followWrapper{
    margin-left: 1rem !important;
}

.infoWrapper>h2{
    font-size: 1.9rem !important;
    font-weight: 800;
}

.infoWrapper>span{
    font-size: 1.5rem;
    color: var(--twittie-light);
}

.infoWrapper>p{
    font-size: 1.5rem;
    margin: .8rem 0;
}

.bioInfo{
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    font-size: 1.5rem;
    margin-left: -.4rem;
}

.bioInfo>div{
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--twittie-light);
    margin-right: 2rem;
}

.bioInfo>div .MuiSvgIcon-root{
    margin-right: .5rem;
    font-size: 2rem !important;
}

.countInfo{
    display: flex;
    flex-direction: row;
    padding: 1rem 0;
    align-items: center;
}

.countInfo span{
    display: flex;
    flex-direction: row;
    font-size: 1.5rem;
    font-weight: 650;
    margin-right: 1rem;
    border-bottom: 1px solid white;
}

.countInfo span>p{
    color: var(--twittie-light);
    font-weight: normal;
    margin-left: .5rem;
}

.countInfo span:hover{
    border-bottom: 1px solid var(--twittie-light);
}

.followedInfo{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1rem 0 ;
    flex: 1 1;
}

.followedInfo .MuiAvatar-root{
    width: 4rem;
    height: 4rem;
    margin-right: .5rem;
}

.followedInfo>span{
    color: var(--twittie-light);
    font-size: 1.5rem;
}

.blued{
    color: var(--twittie-color) !important;
}

.themeBottom{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: space-between;
}

.themeSpinner{
    padding:  0 ;
    font-size: 2rem;
    color: var(--twittie-color);
    margin-left: auto;
}
.widgets__trendsContainer{
    display: flex;
    flex-direction: column;
    list-style-type: none;
    padding-left: 0 !important;
}

.widgets__trendsContainer>li{
    display: flex;
    flex-direction: column;
    padding: 1rem 0;
    border-bottom: 1px solid var(--twittie-background);
}

.widgets__trendsContainer>li{
    border-bottom:none;
}

.widgets__trendsContainer li div{
    padding: 0.2rem 0.2rem;
    margin-left: -.2rem;
}

.widgets__trendsContainer li div.popular{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 1.3rem;
    color: var(--twittie-light);
}

.widgets__trendsContainer li div.hashtag{
    font-size: 1.5rem;
    font-weight: 650;
}

.widgets__trendsContainer li div.tweetNumber{
    font-size: 1.3rem;
    color: var(--twittie-light);
}

.widgets__widgetContainerforPhotos{
    margin: .7rem 1rem 1.5rem 2rem;
    /* background-color: var(--twittie-background-2); */
    border-radius: 2rem;

    display: grid;
    grid: repeat(2, 8vw) / auto-flow;
}

.wcfpRow{
    display: grid;
    grid: repeat(1, 8vw) / auto-flow;  
}

.wcfpRow > .square {
    margin: 2px;
    background-color: var(--twittie-background);
    background-size: cover;    
}

.bcA{ border-radius: 2rem 0 0 0 }
.bcB{ border-radius: 0 2rem 0 0 }
.bcC{ border-radius: 0 0 2rem 0 }
.bcD{ border-radius: 0 0 0 2rem }
  
.square img{
    height: 100%;
    object-fit: cover;
}
.statusPost:hover{
    background: var(--twittie-background-2);
}

.statusPost__body{
    padding: 0rem 1rem 1rem 2rem;
    font-size: 1.5rem;
}

.statusPost__body--message{
    padding-bottom: 1rem;
}

.statusPost__body>img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 2rem;
}

@media (min-width: 1200px) {
    .statusPost__body>img {
        width: 50%;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .statusPost__body>img {
        width: 70%;
    }
}

@media (max-width: 991px) {
    .statusPost__body>img {
        width: 100%;
    }
}


.statusPost__body--date{
    color: var(--twittie-light);
    line-height: 3rem;
}

.statusPost__footer{
    border-top: 1px solid var(--twittie-background);
    padding: 1rem 1rem 2rem 2rem;
    border-bottom: 1px solid var(--twittie-background);
}
.cookies-content-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5); /* semi-transparent background */
    -webkit-animation:fadeIn 2.0s ;
            animation:fadeIn 2.0s ;
  }

.cookiesContent {
width: 320px;
display: flex;
flex-direction: column;
align-items: center;
background-color: #fff;
color: #000;
text-align: center;
border-radius: 20px;
padding: 30px 30px 70px;
}

.cookiesContent button.close {
    width: 30px;
    font-size: 20px;
    color: #c0c5cb;
    align-self: flex-end;
    background-color: transparent;
    border: none;
    margin-bottom: 10px;
}
.cookiesContent img {
    width: 82px;
    margin-bottom: 15px;
}
.cookiesContent p {
    margin-bottom: 40px;
    font-size: 18px;
}

.cookiesContent button.accept{
  flex: 1 1; 
  /* background-color: #750550; */
  background-color: var(--twittie-darker-color);
  border: none;
  border-radius: 5px;
  padding: 14px;
  font-size: 16px;
  color: #ffffff;
  /* box-shadow: 0px 6px 18px -5px rgb(73, 14, 70); */
}





.bottomNav{
    width: 100%;
    /* position: absolute; */
    position: fixed;
    bottom: 0;
    display: flex;
    flex-direction: row;    
    z-index: 1000;
    background: white;
    padding: 1rem 0;    
}

.bottomNav>nav{
    flex: 1 1;
    display: flex;
    flex-direction: row;    
}

.bottomNav>nav>a{
    flex: 1 1;
    text-align: center;
}

.bottomNav .MuiSvgIcon-root {
    font-size: 2.5rem;
    color: var(--twittie-light);
}


.bottomNav__active .MuiSvgIcon-root{
    color: var(--twittie-darker-color) !important;
}

@media screen and (min-width: 501px){
    .bottomNav{
        display: none;
    }
}

.bottomNav__text{
    color:#333
}
* {
    margin: 0;

}

html{
    font-size: 10px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif;
}

:root{
    --twittie-color: #50b7f5;
    --twittie-darker-color: #1da1f2;
    --twittie-background: #e6ecf0;
    --twittie-background-2: #f5f8fa;
    --twittie-light: rgb(101, 119,134);
    --twittie-hover: #e8f5fe;
    --twittie-hover-2: #ebebeb;
    --twittie-red: rgb(224, 36, 94); 
    
}

.app{
    display: flex;
    height: auto;
    max-width: 1300px;
    /* margin-left: auto;
    margin-right: auto; */
    height: 100vh;

    flex-direction: column;
}

.app__mainContent{
    display: flex;
    flex: 1 1;
    flex-direction: row;
    /* height: 100vh; */
}

.react-responsive-modal-modal{
    top: 4.5vh;
    width: 85vh;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-height: 85vh;
    border-radius: 2rem;
    padding: 0;
}

.react-responsive-modal-closeButton{
    display: none;
}

.MuiCircularProgress-colorPrimary{
    color: #50b7f5 !important;
    color: var(--twittie-color) !important;
}

.app__main{
    flex: 1 1;
    display: flex;
    flex-direction: row;
}

.MuiPopover-paper{
    box-shadow: rgba(101, 119, 134, 0.2) 0px 0px 15px, rgba(101, 119, 134, 0.15) 0px 0px 3px 1px !important;
}

.emoji-picker-react input.emoji-search{
    border: 2px solid #50b7f5 !important;
    border: 2px solid var(--twittie-color) !important;
    border-radius: 3rem;
}

ul{
    list-style: none;
    padding: 0;
}

.tabbarMenu .react-swipeable-view-container .MuiBox-root-6 {
    padding: 0;
}

.addMoreLeftPadd{
    padding-left: 2rem;
}


@media screen and (max-width: 500px){
    .app__mainContent{
        /* height: calc(100vh - 4.5rem);         */
    }
}
.modal-content-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5); /* semi-transparent background */
    -webkit-animation:fadeIn 2.0s ;
            animation:fadeIn 2.0s ;
}

.modalContent {
    width: 320px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    color: #000;
    text-align: center;
    border-radius: 20px;
    padding: 30px 30px 70px;
}

.modalContent button.close {
    width: 30px;
    font-size: 20px;
    color: #c0c5cb;
    align-self: flex-end;
    background-color: transparent;
    border: none;
    margin-bottom: 10px;
}
.modalContent img {
    width: 82px;
    margin-bottom: 15px;
    border-radius:50%
}
.modalContent p {
    margin-bottom: 40px;
    font-size: 18px;
}


.modalContent button.accept{
  flex: 1 1; 
  background-color: var(--twittie-darker-color);
  border: none;
  border-radius: 5px;
  padding: 14px;
  font-size: 16px;
  color: #ffffff;
  box-shadow: 0px 6px 18px -5px rgb(73, 14, 70);
}

.loader{
    width: 100%;
    padding-top: 2rem;
    display: flex;
    justify-content: center !important;
}

.google-logo {
    width: 5%; /* Adjust size as needed */
    height: 5%; /* Adjust size as needed */
    margin-right: 10px; /* Adjust spacing between logo and text */
  }
